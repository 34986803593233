import React, { useState, useEffect } from "react";
import { Animate } from "react-move";
import { easeQuadIn } from "d3-ease";

const AnimatedProgressProvider = ({
  valueStart = 0,
  valueEnd,
  duration,
  easingFunction = easeQuadIn,
  repeat,
  children,
}: {
  valueStart?: number;
  valueEnd: number;
  duration: number;
  easingFunction?: any;
  repeat?: boolean;
  children: (value: number) => JSX.Element;
}) => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (repeat) {
      interval = setInterval(() => {
        setIsAnimated((prevIsAnimated) => !prevIsAnimated);
      }, duration * 1000);
    } else {
      setIsAnimated(true);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [duration, repeat]);

  return (
    // @ts-ignore
    <Animate
      start={() => ({
        value: valueStart,
      })}
      update={() => ({
        value: [isAnimated ? valueEnd : valueStart],
        timing: {
          duration: duration * 1000,
          ease: easingFunction,
        },
      })}
    >
      {({ value }) => children(value)}
    </Animate>
  );
};

export default AnimatedProgressProvider;
