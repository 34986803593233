import { Colors } from "../../common/models/UserType";
import { Coin } from "../../common/models/Coin";

export const cpviData = [
  { time: "1647809527000", value: 50 },
  { time: "1647806400000", value: 50 },
  { time: "1647802800000", value: 100 },
  { time: "1647799200000", value: 90 },
  { time: "1647795600000", value: 80 },
  { time: "1647792000000", value: 70 },
  { time: "1647788400000", value: 50 },
  { time: "1647784800000", value: 40 },
  { time: "1647781200000", value: 50 },
  { time: "1647777600000", value: 60 },
  { time: "1647774000000", value: 50 },
  { time: "1647770400000", value: 50 },
  { time: "1647766800000", value: 50 },
  { time: "1647763200000", value: 50 },
  { time: "1647759600000", value: 50 },
  { time: "1647756000000", value: 50 },
  { time: "1647752400000", value: 50 },
  { time: "1647748800000", value: 30 },
  { time: "1647745200000", value: 50 },
  { time: "1647741600000", value: 50 },
  { time: "1647738000000", value: 50 },
  { time: "1647734400000", value: 50 },
  { time: "1647730800000", value: 50 },
  { time: "1647727200000", value: 50 },
  { time: "1647723600000", value: 50 },
  { time: "1647720000000", value: 50 },
  { time: "1647716400000", value: 50 },
  { time: "1647712800000", value: 50 },
  { time: "1647709200000", value: 50 },
  { time: "1647705600000", value: 50 },
  { time: "1647702000000", value: 50 },
  { time: "1647698400000", value: 50 },
  { time: "1647694800000", value: 50 },
  { time: "1647691200000", value: 50 },
  { time: "1647687600000", value: 50 },
  { time: "1647684000000", value: 50 },
  { time: "1647680400000", value: 50 },
  { time: "1647676800000", value: 50 },
  { time: "1647673200000", value: 50 },
  { time: "1647669600000", value: 50 },
  { time: "1647666000000", value: 50 },
  { time: "1647662400000", value: 50 },
  { time: "1647658800000", value: 50 },
  { time: "1647655200000", value: 50 },
  { time: "1647651600000", value: 50 },
  { time: "1647648000000", value: 50 },
  { time: "1647644400000", value: 50 },
  { time: "1647640800000", value: 44.68 },
  { time: "1647637200000", value: 50 },
  { time: "1647633600000", value: 50 },
  { time: "1647630000000", value: 50 },
  { time: "1647626400000", value: 50 },
  { time: "1647622800000", value: 50 },
  { time: "1647619200000", value: 50 },
  { time: "1647615600000", value: 50 },
  { time: "1647612000000", value: 50 },
  { time: "1647608400000", value: 50 },
  { time: "1647604800000", value: 50 },
  { time: "1647601200000", value: 50 },
  { time: "1647597600000", value: 50 },
  { time: "1647594000000", value: 50 },
  { time: "1647590400000", value: 50 },
  { time: "1647586800000", value: 50 },
  { time: "1647583200000", value: 50 },
  { time: "1647579600000", value: 50 },
  { time: "1647576000000", value: 50 },
  { time: "1647572400000", value: 50 },
  { time: "1647568800000", value: 50 },
  { time: "1647565200000", value: 50 },
  { time: "1647561600000", value: 50 },
  { time: "1647558000000", value: 50 },
  { time: "1647554400000", value: 50 },
  { time: "1647550800000", value: 50 },
  { time: "1647547200000", value: 50 },
  { time: "1647543600000", value: 50 },
  { time: "1647540000000", value: 50 },
  { time: "1647536400000", value: 50 },
  { time: "1647532800000", value: 50 },
  { time: "1647529200000", value: 50 },
  { time: "1647525600000", value: 50 },
  { time: "1647522000000", value: 50 },
  { time: "1647518400000", value: 50 },
  { time: "1647514800000", value: 50 },
  { time: "1647511200000", value: 50 },
  { time: "1647507600000", value: 50 },
  { time: "1647504000000", value: 50 },
  { time: "1647500400000", value: 50 },
  { time: "1647496800000", value: 50 },
  { time: "1647493200000", value: 50 },
  { time: "1647489600000", value: 50 },
  { time: "1647486000000", value: 50 },
  { time: "1647482400000", value: 50 },
  { time: "1647478800000", value: 50 },
  { time: "1647475200000", value: 50 },
  { time: "1647471600000", value: 50 },
  { time: "1647468000000", value: 50 },
  { time: "1647464400000", value: 50 },
  { time: "1647460800000", value: 50 },
  { time: "1647457200000", value: 50 },
  { time: "1647453600000", value: 50 },
  { time: "1647450000000", value: 50 },
  { time: "1647446400000", value: 50 },
  { time: "1647442800000", value: 50 },
  { time: "1647439200000", value: 50 },
  { time: "1647435600000", value: 50 },
  { time: "1647432000000", value: 50 },
  { time: "1647428400000", value: 50 },
  { time: "1647424800000", value: 50 },
  { time: "1647421200000", value: 50 },
  { time: "1647417600000", value: 50 },
  { time: "1647414000000", value: 50 },
  { time: "1647410400000", value: 50 },
  { time: "1647406800000", value: 50 },
  { time: "1647403200000", value: 50 },
  { time: "1647399600000", value: 50 },
  { time: "1647396000000", value: 50 },
  { time: "1647392400000", value: 50 },
  { time: "1647388800000", value: 50 },
  { time: "1647385200000", value: 50 },
  { time: "1647381600000", value: 50 },
  { time: "1647378000000", value: 50 },
  { time: "1647374400000", value: 50 },
  { time: "1647370800000", value: 50 },
  { time: "1647367200000", value: 50 },
  { time: "1647363600000", value: 50 },
  { time: "1647360000000", value: 50 },
  { time: "1647356400000", value: 50 },
  { time: "1647352800000", value: 50 },
  { time: "1647349200000", value: 50 },
  { time: "1647345600000", value: 50 },
  { time: "1647342000000", value: 50 },
  { time: "1647338400000", value: 50 },
  { time: "1647334800000", value: 50 },
  { time: "1647331200000", value: 50 },
  { time: "1647327600000", value: 50 },
  { time: "1647324000000", value: 50 },
  { time: "1647320400000", value: 50 },
  { time: "1647316800000", value: 50 },
  { time: "1647313200000", value: 50 },
  { time: "1647309600000", value: 50 },
  { time: "1647306000000", value: 50 },
  { time: "1647302400000", value: 50 },
  { time: "1647298800000", value: 50 },
  { time: "1647295200000", value: 50 },
  { time: "1647291600000", value: 50 },
  { time: "1647288000000", value: 50 },
  { time: "1647284400000", value: 50 },
  { time: "1647280800000", value: 50 },
  { time: "1647277200000", value: 50 },
  { time: "1647273600000", value: 50 },
  { time: "1647270000000", value: 50 },
  { time: "1647266400000", value: 50 },
  { time: "1647262800000", value: 50 },
  { time: "1647259200000", value: 50 },
  { time: "1647255600000", value: 50 },
  { time: "1647252000000", value: 50 },
  { time: "1647248400000", value: 50 },
  { time: "1647244800000", value: 50 },
  { time: "1647241200000", value: 50 },
  { time: "1647237600000", value: 50 },
  { time: "1647234000000", value: 50 },
  { time: "1647230400000", value: 50 },
  { time: "1647226800000", value: 50 },
  { time: "1647223200000", value: 50 },
  { time: "1647219600000", value: 50 },
  { time: "1647216000000", value: 50 },
  { time: "1647212400000", value: 50 },
  { time: "1647208800000", value: 50 },
  { time: "1647205200000", value: 50 },
  { time: "1647201600000", value: 50 },
  { time: "1647198000000", value: 50 },
  { time: "1647194400000", value: 50 },
  { time: "1647190800000", value: 50 },
  { time: "1647187200000", value: 50 },
  { time: "1647183600000", value: 50 },
  { time: "1647180000000", value: 50 },
  { time: "1647176400000", value: 50 },
  { time: "1647172800000", value: 50 },
  { time: "1647169200000", value: 50 },
  { time: "1647165600000", value: 50 },
  { time: "1647162000000", value: 50 },
  { time: "1647158400000", value: 50 },
  { time: "1647154800000", value: 50 },
  { time: "1647151200000", value: 50 },
  { time: "1647147600000", value: 50 },
  { time: "1647144000000", value: 50 },
  { time: "1647140400000", value: 50 },
  { time: "1647136800000", value: 50 },
  { time: "1647133200000", value: 50 },
  { time: "1647129600000", value: 50 },
  { time: "1647126000000", value: 50 },
  { time: "1647122400000", value: 50 },
  { time: "1647118800000", value: 50 },
  { time: "1647115200000", value: 50 },
  { time: "1647111600000", value: 50 },
  { time: "1647108000000", value: 50 },
  { time: "1647104400000", value: 50 },
  { time: "1647100800000", value: 50 },
  { time: "1647097200000", value: 50 },
  { time: "1647093600000", value: 50 },
  { time: "1647090000000", value: 50 },
  { time: "1647086400000", value: 50 },
  { time: "1647082800000", value: 50 },
  { time: "1647079200000", value: 50 },
  { time: "1647075600000", value: 50 },
  { time: "1647072000000", value: 50 },
  { time: "1647068400000", value: 50 },
  { time: "1647064800000", value: 50 },
  { time: "1647061200000", value: 50 },
  { time: "1647057600000", value: 50 },
  { time: "1647054000000", value: 50 },
  { time: "1647050400000", value: 50 },
  { time: "1647046800000", value: 50 },
  { time: "1647043200000", value: 50 },
  { time: "1647039600000", value: 50 },
  { time: "1647036000000", value: 50 },
  { time: "1647032400000", value: 50 },
  { time: "1647028800000", value: 50 },
  { time: "1647025200000", value: 50 },
  { time: "1647021600000", value: 50 },
  { time: "1647018000000", value: 50 },
  { time: "1647014400000", value: 50 },
  { time: "1647010800000", value: 50 },
  { time: "1647007200000", value: 50 },
  { time: "1647003600000", value: 50 },
  { time: "1647000000000", value: 50 },
  { time: "1646996400000", value: 50 },
  { time: "1646992800000", value: 50 },
  { time: "1646989200000", value: 50 },
  { time: "1646985600000", value: 50 },
  { time: "1646982000000", value: 50 },
  { time: "1646978400000", value: 50 },
  { time: "1646974800000", value: 50 },
  { time: "1646971200000", value: 50 },
  { time: "1646967600000", value: 50 },
  { time: "1646964000000", value: 50 },
  { time: "1646960400000", value: 50 },
  { time: "1646956800000", value: 50 },
  { time: "1646953200000", value: 50 },
  { time: "1646949600000", value: 50 },
  { time: "1646946000000", value: 50 },
  { time: "1646942400000", value: 50 },
  { time: "1646938800000", value: 50 },
  { time: "1646935200000", value: 50 },
  { time: "1646931600000", value: 50 },
  { time: "1646928000000", value: 50 },
  { time: "1646924400000", value: 50 },
  { time: "1646920800000", value: 50 },
  { time: "1646917200000", value: 50 },
  { time: "1646913600000", value: 50 },
  { time: "1646910000000", value: 50 },
  { time: "1646906400000", value: 50 },
  { time: "1646902800000", value: 50 },
  { time: "1646899200000", value: 50 },
  { time: "1646895600000", value: 50 },
  { time: "1646892000000", value: 50 },
  { time: "1646888400000", value: 50 },
  { time: "1646884800000", value: 50 },
  { time: "1646881200000", value: 50 },
  { time: "1646877600000", value: 50 },
  { time: "1646874000000", value: 50 },
  { time: "1646870400000", value: 50 },
  { time: "1646866800000", value: 50 },
  { time: "1646863200000", value: 50 },
  { time: "1646859600000", value: 50 },
  { time: "1646856000000", value: 50 },
  { time: "1646852400000", value: 50 },
  { time: "1646848800000", value: 50 },
  { time: "1646845200000", value: 50 },
  { time: "1646841600000", value: 50 },
  { time: "1646838000000", value: 50 },
  { time: "1646834400000", value: 50 },
  { time: "1646830800000", value: 50 },
  { time: "1646827200000", value: 50 },
  { time: "1646823600000", value: 50 },
  { time: "1646820000000", value: 50 },
  { time: "1646816400000", value: 50 },
  { time: "1646812800000", value: 50 },
  { time: "1646809200000", value: 50 },
  { time: "1646805600000", value: 50 },
  { time: "1646802000000", value: 50 },
  { time: "1646798400000", value: 50 },
  { time: "1646794800000", value: 50 },
  { time: "1646791200000", value: 50 },
  { time: "1646787600000", value: 50 },
  { time: "1646784000000", value: 50 },
  { time: "1646780400000", value: 50 },
  { time: "1646776800000", value: 50 },
  { time: "1646773200000", value: 50 },
  { time: "1646769600000", value: 50 },
  { time: "1646766000000", value: 50 },
  { time: "1646762400000", value: 50 },
  { time: "1646758800000", value: 50 },
  { time: "1646755200000", value: 50 },
  { time: "1646751600000", value: 50 },
  { time: "1646748000000", value: 50 },
  { time: "1646744400000", value: 50 },
  { time: "1646740800000", value: 50 },
  { time: "1646737200000", value: 50 },
  { time: "1646733600000", value: 50 },
  { time: "1646730000000", value: 50 },
  { time: "1646726400000", value: 50 },
  { time: "1646722800000", value: 50 },
  { time: "1646719200000", value: 50 },
  { time: "1646715600000", value: 50 },
  { time: "1646712000000", value: 50 },
  { time: "1646708400000", value: 50 },
  { time: "1646704800000", value: 50 },
  { time: "1646701200000", value: 50 },
  { time: "1646697600000", value: 50 },
  { time: "1646694000000", value: 50 },
  { time: "1646690400000", value: 50 },
  { time: "1646686800000", value: 50 },
  { time: "1646683200000", value: 50 },
  { time: "1646679600000", value: 50 },
  { time: "1646676000000", value: 50 },
  { time: "1646672400000", value: 50 },
  { time: "1646668800000", value: 50 },
  { time: "1646665200000", value: 50 },
  { time: "1646661600000", value: 50 },
  { time: "1646658000000", value: 50 },
  { time: "1646654400000", value: 50 },
  { time: "1646650800000", value: 50 },
  { time: "1646647200000", value: 50 },
  { time: "1646643600000", value: 50 },
  { time: "1646640000000", value: 50 },
  { time: "1646636400000", value: 50 },
  { time: "1646632800000", value: 50 },
  { time: "1646629200000", value: 50 },
  { time: "1646625600000", value: 50 },
  { time: "1646622000000", value: 50 },
  { time: "1646618400000", value: 50 },
  { time: "1646614800000", value: 50 },
  { time: "1646611200000", value: 50 },
  { time: "1646607600000", value: 50 },
  { time: "1646604000000", value: 50 },
  { time: "1646600400000", value: 50 },
  { time: "1646596800000", value: 50 },
  { time: "1646593200000", value: 50 },
  { time: "1646589600000", value: 50 },
  { time: "1646586000000", value: 50 },
  { time: "1646582400000", value: 50 },
  { time: "1646578800000", value: 50 },
  { time: "1646575200000", value: 50 },
  { time: "1646571600000", value: 50 },
  { time: "1646568000000", value: 50 },
  { time: "1646564400000", value: 50 },
  { time: "1646560800000", value: 50 },
  { time: "1646557200000", value: 50 },
  { time: "1646553600000", value: 50 },
  { time: "1646550000000", value: 50 },
  { time: "1646546400000", value: 50 },
  { time: "1646542800000", value: 50 },
  { time: "1646539200000", value: 50 },
  { time: "1646535600000", value: 50 },
  { time: "1646532000000", value: 50 },
  { time: "1646528400000", value: 50 },
  { time: "1646524800000", value: 50 },
  { time: "1646521200000", value: 50 },
  { time: "1646517600000", value: 50 },
  { time: "1646514000000", value: 50 },
  { time: "1646510400000", value: 50 },
  { time: "1646506800000", value: 50 },
  { time: "1646503200000", value: 50 },
  { time: "1646499600000", value: 50 },
  { time: "1646496000000", value: 50 },
  { time: "1646492400000", value: 50 },
  { time: "1646488800000", value: 50 },
  { time: "1646485200000", value: 50 },
  { time: "1646481600000", value: 50 },
  { time: "1646478000000", value: 50 },
  { time: "1646474400000", value: 50 },
  { time: "1646470800000", value: 50 },
  { time: "1646467200000", value: 50 },
  { time: "1646463600000", value: 50 },
  { time: "1646460000000", value: 50 },
  { time: "1646456400000", value: 50 },
  { time: "1646452800000", value: 50 },
  { time: "1646449200000", value: 50 },
  { time: "1646445600000", value: 50 },
  { time: "1646442000000", value: 50 },
  { time: "1646438400000", value: 50 },
  { time: "1646434800000", value: 50 },
  { time: "1646431200000", value: 50 },
  { time: "1646427600000", value: 50 },
  { time: "1646424000000", value: 50 },
  { time: "1646420400000", value: 50 },
  { time: "1646416800000", value: 50 },
  { time: "1646413200000", value: 50 },
  { time: "1646409600000", value: 50 },
  { time: "1646406000000", value: 50 },
  { time: "1646402400000", value: 50 },
  { time: "1646398800000", value: 50 },
  { time: "1646395200000", value: 50 },
  { time: "1646391600000", value: 50 },
  { time: "1646388000000", value: 50 },
  { time: "1646384400000", value: 50 },
  { time: "1646380800000", value: 50 },
  { time: "1646377200000", value: 50 },
  { time: "1646373600000", value: 50 },
  { time: "1646370000000", value: 50 },
  { time: "1646366400000", value: 50 },
  { time: "1646362800000", value: 50 },
  { time: "1646359200000", value: 50 },
  { time: "1646355600000", value: 50 },
  { time: "1646352000000", value: 50 },
  { time: "1646348400000", value: 50 },
  { time: "1646344800000", value: 50 },
  { time: "1646341200000", value: 50 },
  { time: "1646337600000", value: 50 },
  { time: "1646334000000", value: 50 },
  { time: "1646330400000", value: 50 },
  { time: "1646326800000", value: 50 },
  { time: "1646323200000", value: 50 },
  { time: "1646319600000", value: 50 },
  { time: "1646316000000", value: 50 },
  { time: "1646312400000", value: 50 },
  { time: "1646308800000", value: 50 },
  { time: "1646305200000", value: 50 },
  { time: "1646301600000", value: 50 },
  { time: "1646298000000", value: 50 },
  { time: "1646294400000", value: 50 },
  { time: "1646290800000", value: 50 },
  { time: "1646287200000", value: 50 },
  { time: "1646283600000", value: 50 },
  { time: "1646280000000", value: 50 },
  { time: "1646276400000", value: 50 },
  { time: "1646272800000", value: 50 },
  { time: "1646269200000", value: 50 },
  { time: "1646265600000", value: 50 },
  { time: "1646262000000", value: 50 },
  { time: "1646258400000", value: 50 },
  { time: "1646254800000", value: 50 },
  { time: "1646251200000", value: 50 },
  { time: "1646247600000", value: 50 },
  { time: "1646244000000", value: 50 },
  { time: "1646240400000", value: 50 },
  { time: "1646236800000", value: 50 },
  { time: "1646233200000", value: 50 },
  { time: "1646229600000", value: 50 },
  { time: "1646226000000", value: 50 },
  { time: "1646222400000", value: 50 },
  { time: "1646218800000", value: 50 },
  { time: "1646215200000", value: 50 },
  { time: "1646211600000", value: 50 },
  { time: "1646208000000", value: 50 },
  { time: "1646204400000", value: 50 },
  { time: "1646200800000", value: 50 },
  { time: "1646197200000", value: 50 },
  { time: "1646193600000", value: 50 },
  { time: "1646190000000", value: 50 },
  { time: "1646186400000", value: 50 },
  { time: "1646182800000", value: 50 },
  { time: "1646179200000", value: 50 },
  { time: "1646175600000", value: 50 },
  { time: "1646172000000", value: 50 },
  { time: "1646168400000", value: 50 },
  { time: "1646164800000", value: 50 },
  { time: "1646161200000", value: 50 },
  { time: "1646157600000", value: 50 },
  { time: "1646154000000", value: 50 },
  { time: "1646150400000", value: 50 },
  { time: "1646146800000", value: 50 },
  { time: "1646143200000", value: 50 },
  { time: "1646139600000", value: 50 },
  { time: "1646136000000", value: 50 },
  { time: "1646132400000", value: 50 },
  { time: "1646128800000", value: 50 },
  { time: "1646125200000", value: 50 },
  { time: "1646121600000", value: 50 },
  { time: "1646118000000", value: 50 },
  { time: "1646114400000", value: 50 },
  { time: "1646110800000", value: 50 },
  { time: "1646107200000", value: 50 },
  { time: "1646103600000", value: 50 },
  { time: "1646100000000", value: 50 },
  { time: "1646096400000", value: 50 },
  { time: "1646092800000", value: 50 },
  { time: "1646089200000", value: 50 },
  { time: "1646085600000", value: 50 },
  { time: "1646082000000", value: 50 },
  { time: "1646078400000", value: 50 },
  { time: "1646074800000", value: 50 },
  { time: "1646071200000", value: 50 },
  { time: "1646067600000", value: 50 },
  { time: "1646064000000", value: 50 },
  { time: "1646060400000", value: 50 },
  { time: "1646056800000", value: 50 },
  { time: "1646053200000", value: 50 },
  { time: "1646049600000", value: 50 },
  { time: "1646046000000", value: 50 },
  { time: "1646042400000", value: 50 },
  { time: "1646038800000", value: 50 },
  { time: "1646035200000", value: 50 },
  { time: "1646031600000", value: 50 },
  { time: "1646028000000", value: 50 },
  { time: "1646024400000", value: 50 },
  { time: "1646020800000", value: 50 },
  { time: "1646017200000", value: 50 },
  { time: "1646013600000", value: 50 },
  { time: "1646010000000", value: 50 },
  { time: "1646006400000", value: 50 },
  { time: "1646002800000", value: 50 },
  { time: "1645999200000", value: 50 },
  { time: "1645995600000", value: 50 },
  { time: "1645992000000", value: 50 },
  { time: "1645988400000", value: 50 },
  { time: "1645984800000", value: 50 },
  { time: "1645981200000", value: 50 },
  { time: "1645977600000", value: 50 },
  { time: "1645974000000", value: 50 },
  { time: "1645970400000", value: 50 },
  { time: "1645966800000", value: 50 },
  { time: "1645963200000", value: 50 },
  { time: "1645959600000", value: 50 },
  { time: "1645956000000", value: 50 },
  { time: "1645952400000", value: 50 },
  { time: "1645948800000", value: 50 },
  { time: "1645945200000", value: 50 },
  { time: "1645941600000", value: 50 },
  { time: "1645938000000", value: 50 },
  { time: "1645934400000", value: 50 },
  { time: "1645930800000", value: 50 },
  { time: "1645927200000", value: 50 },
  { time: "1645923600000", value: 50 },
  { time: "1645920000000", value: 50 },
  { time: "1645916400000", value: 50 },
  { time: "1645912800000", value: 50 },
  { time: "1645909200000", value: 50 },
  { time: "1645905600000", value: 50 },
  { time: "1645902000000", value: 50 },
  { time: "1645898400000", value: 50 },
  { time: "1645894800000", value: 50 },
  { time: "1645891200000", value: 50 },
  { time: "1645887600000", value: 50 },
  { time: "1645884000000", value: 50 },
  { time: "1645880400000", value: 50 },
  { time: "1645876800000", value: 50 },
  { time: "1645873200000", value: 50 },
  { time: "1645869600000", value: 50 },
  { time: "1645866000000", value: 50 },
  { time: "1645862400000", value: 50 },
  { time: "1645858800000", value: 50 },
  { time: "1645855200000", value: 50 },
  { time: "1645851600000", value: 50 },
  { time: "1645848000000", value: 50 },
  { time: "1645844400000", value: 50 },
  { time: "1645840800000", value: 50 },
  { time: "1645837200000", value: 50 },
  { time: "1645833600000", value: 50 },
  { time: "1645830000000", value: 50 },
  { time: "1645826400000", value: 50 },
  { time: "1645822800000", value: 50 },
  { time: "1645819200000", value: 50 },
  { time: "1645815600000", value: 50 },
  { time: "1645812000000", value: 50 },
  { time: "1645808400000", value: 50 },
  { time: "1645804800000", value: 50 },
  { time: "1645801200000", value: 50 },
  { time: "1645797600000", value: 50 },
  { time: "1645794000000", value: 50 },
  { time: "1645790400000", value: 50 },
  { time: "1645786800000", value: 50 },
  { time: "1645783200000", value: 50 },
  { time: "1645779600000", value: 50 },
  { time: "1645776000000", value: 50 },
  { time: "1645772400000", value: 50 },
  { time: "1645768800000", value: 50 },
  { time: "1645765200000", value: 50 },
  { time: "1645761600000", value: 50 },
  { time: "1645758000000", value: 50 },
  { time: "1645754400000", value: 50 },
  { time: "1645750800000", value: 50 },
  { time: "1645747200000", value: 50 },
  { time: "1645743600000", value: 50 },
  { time: "1645740000000", value: 50 },
  { time: "1645736400000", value: 50 },
  { time: "1645732800000", value: 50 },
  { time: "1645729200000", value: 50 },
  { time: "1645725600000", value: 50 },
  { time: "1645722000000", value: 50 },
  { time: "1645718400000", value: 50 },
  { time: "1645714800000", value: 50 },
  { time: "1645711200000", value: 50 },
  { time: "1645707600000", value: 50 },
  { time: "1645704000000", value: 50 },
  { time: "1645700400000", value: 50 },
  { time: "1645696800000", value: 50 },
  { time: "1645693200000", value: 50 },
  { time: "1645689600000", value: 50 },
  { time: "1645686000000", value: 50 },
  { time: "1645682400000", value: 50 },
  { time: "1645678800000", value: 50 },
  { time: "1645675200000", value: 50 },
  { time: "1645671600000", value: 50 },
  { time: "1645668000000", value: 50 },
  { time: "1645664400000", value: 50 },
  { time: "1645660800000", value: 50 },
  { time: "1645657200000", value: 50 },
  { time: "1645653600000", value: 50 },
  { time: "1645650000000", value: 50 },
  { time: "1645646400000", value: 50 },
  { time: "1645642800000", value: 50 },
  { time: "1645639200000", value: 50 },
  { time: "1645635600000", value: 50 },
  { time: "1645632000000", value: 50 },
  { time: "1645628400000", value: 50 },
  { time: "1645624800000", value: 50 },
  { time: "1645621200000", value: 50 },
  { time: "1645617600000", value: 50 },
  { time: "1645614000000", value: 50 },
  { time: "1645610400000", value: 50 },
  { time: "1645606800000", value: 50 },
  { time: "1645603200000", value: 50 },
  { time: "1645599600000", value: 50 },
  { time: "1645596000000", value: 50 },
  { time: "1645592400000", value: 50 },
  { time: "1645588800000", value: 50 },
  { time: "1645585200000", value: 50 },
  { time: "1645581600000", value: 50 },
  { time: "1645578000000", value: 50 },
  { time: "1645574400000", value: 50 },
  { time: "1645570800000", value: 50 },
  { time: "1645567200000", value: 50 },
  { time: "1645563600000", value: 50 },
  { time: "1645560000000", value: 50 },
  { time: "1645556400000", value: 50 },
  { time: "1645552800000", value: 50 },
  { time: "1645549200000", value: 50 },
  { time: "1645545600000", value: 50 },
  { time: "1645542000000", value: 50 },
  { time: "1645538400000", value: 50 },
  { time: "1645534800000", value: 50 },
  { time: "1645531200000", value: 50 },
  { time: "1645527600000", value: 50 },
  { time: "1645524000000", value: 50 },
  { time: "1645520400000", value: 50 },
  { time: "1645516800000", value: 50 },
  { time: "1645513200000", value: 50 },
  { time: "1645509600000", value: 50 },
  { time: "1645506000000", value: 50 },
  { time: "1645502400000", value: 50 },
  { time: "1645498800000", value: 50 },
  { time: "1645495200000", value: 50 },
  { time: "1645491600000", value: 50 },
  { time: "1645488000000", value: 50 },
  { time: "1645484400000", value: 50 },
  { time: "1645480800000", value: 50 },
  { time: "1645477200000", value: 50 },
  { time: "1645473600000", value: 50 },
  { time: "1645470000000", value: 50 },
  { time: "1645466400000", value: 50 },
  { time: "1645462800000", value: 50 },
  { time: "1645459200000", value: 50 },
  { time: "1645455600000", value: 50 },
  { time: "1645452000000", value: 50 },
  { time: "1645448400000", value: 50 },
  { time: "1645444800000", value: 50 },
  { time: "1645441200000", value: 50 },
  { time: "1645437600000", value: 50 },
  { time: "1645434000000", value: 50 },
  { time: "1645430400000", value: 50 },
  { time: "1645426800000", value: 50 },
  { time: "1645423200000", value: 50 },
  { time: "1645419600000", value: 50 },
  { time: "1645416000000", value: 50 },
  { time: "1645412400000", value: 50 },
  { time: "1645408800000", value: 50 },
  { time: "1645405200000", value: 50 },
  { time: "1645401600000", value: 50 },
  { time: "1645398000000", value: 50 },
  { time: "1645394400000", value: 50 },
  { time: "1645390800000", value: 50 },
  { time: "1645387200000", value: 50 },
  { time: "1645383600000", value: 50 },
  { time: "1645380000000", value: 50 },
  { time: "1645376400000", value: 50 },
  { time: "1645372800000", value: 50 },
  { time: "1645369200000", value: 50 },
  { time: "1645365600000", value: 50 },
  { time: "1645362000000", value: 50 },
  { time: "1645358400000", value: 50 },
  { time: "1645354800000", value: 50 },
  { time: "1645351200000", value: 50 },
  { time: "1645347600000", value: 50 },
  { time: "1645344000000", value: 50 },
  { time: "1645340400000", value: 50 },
  { time: "1645336800000", value: 50 },
  { time: "1645333200000", value: 50 },
  { time: "1645329600000", value: 50 },
  { time: "1645326000000", value: 50 },
  { time: "1645322400000", value: 50 },
  { time: "1645318800000", value: 50 },
  { time: "1645315200000", value: 50 },
  { time: "1645311600000", value: 50 },
  { time: "1645308000000", value: 50 },
  { time: "1645304400000", value: 50 },
  { time: "1645300800000", value: 50 },
  { time: "1645297200000", value: 50 },
  { time: "1645293600000", value: 50 },
  { time: "1645290000000", value: 50 },
  { time: "1645286400000", value: 50 },
  { time: "1645282800000", value: 50 },
  { time: "1645279200000", value: 50 },
  { time: "1645275600000", value: 50 },
  { time: "1645272000000", value: 50 },
  { time: "1645268400000", value: 50 },
  { time: "1645264800000", value: 50 },
  { time: "1645261200000", value: 50 },
  { time: "1645257600000", value: 50 },
  { time: "1645254000000", value: 50 },
  { time: "1645250400000", value: 50 },
  { time: "1645246800000", value: 50 },
  { time: "1645243200000", value: 50 },
  { time: "1645239600000", value: 50 },
  { time: "1645236000000", value: 50 },
  { time: "1645232400000", value: 50 },
  { time: "1645228800000", value: 50 },
  { time: "1645225200000", value: 50 },
  { time: "1645221600000", value: 50 },
  { time: "1645218000000", value: 50 },
];

export const coins: { [key: string]: Coin } = {
  CAKE: {
    symbol: "CAKE",
    price: 39.97549,
    name: "PancakeSwap",
    id: 4195,
    trend: -2.17,
  },
  BNB: {
    symbol: "BNB",
    price: 39.97549,
    name: "BNB",
    id: 4195,
    trend: -2.17,
  },
  FTT: {
    symbol: "FTT",
    price: 39.97549,
    name: "FTX Token",
    id: 4195,
    trend: -2.17,
  },
  HBAR: {
    symbol: "HBAR",
    price: 0.20472,
    name: "Hedera",
    id: 22,
    trend: -2.459,
  },
  SAND: {
    symbol: "SAND",
    name: "The Sandbox",
    id: 6210,
    price: 2.9493,
    trend: -1.909,
  },
  VET: {
    symbol: "VET",
    name: "VeChain",
    id: 23,
    trend: -1.795,
    price: 0.049516,
  },
  NEAR: {
    symbol: "NEAR",
    name: "NEAR Protocol",
    id: 14,
    trend: 0.475,
    price: 10.569,
  },
  UNI: {
    symbol: "UNI",
    name: "Uniswap",
    id: 7083,
    trend: -3.326,
    price: 8.72,
  },
  STX: {
    symbol: "STX",
    name: "Stacks",
    id: 35,
    trend: -2.632,
    price: 1.11,
  },
  BSV: {
    symbol: "BSV",
    name: "Bitcoin SV",
    id: 38,
    trend: -1.33,
    price: 79.95,
  },
  FIL: {
    symbol: "FIL",
    name: "Filecoin",
    id: 25,
    trend: -1.251,
    price: 18.87,
  },
  DAI: {
    symbol: "DAI",
    name: "Dai",
    id: 4943,
    trend: -0.001,
    price: 0.999775,
  },
  EGLD: {
    symbol: "EGLD",
    name: "Elrond",
    id: 29,
    trend: -1.314,
    price: 135.97,
  },
  AXS: {
    symbol: "AXS",
    name: "Axie Infinity",
    id: 6783,
    trend: -3.239,
    price: 50.19,
  },
  XMR: {
    symbol: "XMR",
    name: "Monero",
    id: 28,
    trend: 2.074,
    price: 169.34,
  },
  CRO: {
    symbol: "CRO",
    name: "Crypto.com Coin",
    id: 3635,
    trend: -1.514,
    price: 0.4033,
  },
  DOT: {
    symbol: "DOT",
    name: "Polkadot",
    id: 8,
    trend: -2.483,
    price: 16.89,
  },
  XTZ: {
    symbol: "XTZ",
    name: "Tezos",
    id: 30,
    trend: -3.185,
    price: 3.04,
  },
  ALGO: {
    symbol: "ALGO",
    name: "Algorand",
    id: 16,
    trend: -1.317,
    price: 0.7642,
  },
  AAVE: {
    symbol: "AAVE",
    name: "Aave",
    id: 7278,
    trend: -3.218,
    price: 120.6,
  },
  AVAX: {
    symbol: "AVAX",
    name: "Avalanche",
    id: 10,
    trend: -1.186,
    price: 75.8,
  },
  ETC: {
    symbol: "ETC",
    name: "Ethereum Classic",
    id: 26,
    trend: -2.048,
    price: 27.26,
  },
  XRP: {
    symbol: "XRP",
    name: "XRP",
    id: 6,
    trend: -2.085,
    price: 0.73875,
  },
  LTC: {
    symbol: "LTC",
    name: "Litecoin",
    id: 15,
    trend: -1.621,
    price: 103.2,
  },
  FTM: {
    symbol: "FTM",
    name: "Fantom",
    id: 17,
    trend: -14.892,
    price: 1.439,
  },
  MATIC: {
    symbol: "MATIC",
    name: "Polygon",
    id: 11,
    trend: -1.908,
    price: 1.4804,
  },
  MANA: {
    symbol: "MANA",
    name: "Decentraland",
    id: 1966,
    trend: -2.383,
    price: 2.499,
  },
  BCH: {
    symbol: "BCH",
    name: "Bitcoin Cash",
    id: 19,
    trend: -1.681,
    price: 289.52,
  },
  GRT: {
    symbol: "GRT",
    name: "The Graph",
    id: 6719,
    trend: -1.297,
    price: 0.3425,
  },
  SHIB: {
    symbol: "SHIB",
    name: "SHIBA INU",
    id: 5994,
    trend: -2.234,
    price: 0.00002407,
  },
  EOS: {
    symbol: "EOS",
    name: "EOS",
    id: 34,
    trend: -1.746,
    price: 2.0142,
  },
  DOGE: {
    symbol: "DOGE",
    name: "Dogecoin",
    id: 9,
    trend: -1.28,
    price: 0.1234,
  },
  ATOM: {
    symbol: "ATOM",
    name: "Cosmos",
    id: 12,
    trend: -5.575,
    price: 29.13,
  },
  BTC: {
    symbol: "BTC",
    name: "Bitcoin",
    id: 1,
    trend: -1.322,
    price: 38881.65,
  },
  SOL: {
    symbol: "SOL",
    name: "Solana",
    id: 5,
    trend: -2.042,
    price: 87.79,
  },
  WBTC: {
    symbol: "WBTC",
    name: "Wrapped Bitcoin",
    id: 3717,
    trend: -1.243,
    price: 38908.2,
  },
  XLM: {
    symbol: "XLM",
    name: "Stellar",
    id: 20,
    trend: -0.558,
    price: 0.179244,
  },
  ETH: {
    symbol: "ETH",
    name: "Ethereum",
    id: 2,
    trend: -0.997,
    price: 2639.37,
  },
  LINK: {
    symbol: "LINK",
    name: "Chainlink",
    id: 1975,
    trend: -2.189,
    price: 13.72,
  },
  TRX: {
    symbol: "TRX",
    name: "TRON",
    id: 18,
    trend: -1.11,
    price: 0.059767,
  },
  ADA: {
    symbol: "ADA",
    name: "Cardano",
    id: 4,
    trend: -2.204,
    price: 0.846327,
  },
  LUNA: {
    symbol: "LUNA",
    name: "Luna Coin",
    id: 674,
    trend: -3.03,
    price: 84.855,
  },
};

export const totals = {
  "BTC-ETH": {
    total: 10,
    success: 3,
  },
  SHIB: {
    total: 11,
    success: 10,
  },
  KDA: {
    total: 7,
    success: 5,
  },
  NEAR: {
    total: 11,
    success: 11,
  },
  HNT: {
    total: 18,
    success: 17,
  },
  COMP: {
    total: 2,
    success: 1,
  },
  BTC: {
    total: 10,
    success: 3,
  },
  "MATIC-SHIB": {
    total: 7,
    success: 3,
  },
  "REEF-FTM": {
    total: 3,
    success: 1,
  },
};

export const allPairs = [
  ["BTC", "ETH"],
  ["ADA", "SOL"],
  ["DOGE", "SHIB"],
  ["ETH", "BNB"],
  ["DOT", "MATIC"],
  ["XRP", "ADA"],
  ["LTC", "XRP"],
  ["CRO", "BNB"],
  ["SAND", "MANA"],
  ["UNI", "CAKE"],
  ["XLM", "XTZ"],
  ["LUNA", "DOGE"],
  ["DOGE", "BABYDOGE"],
  ["LINK", "TRON"],
  ["FTT", "BNB"],
];

export const allCoins = [
  "BTC",
  "ETH",
  "BNB",
  "ADA",
  "SOL",
  "XRP",
  "LUNA",
  "DOGE",
  "DOT",
  "AVAX",
  "SHIB",
  "MATIC",
  "ATOM",
  "WBTC",
  "DAI",
  "CRO",
  "LTC",
  "LINK",
  "NEAR",
  "UNI",
  "FTM",
  "ALGO",
  "TRX",
  "BCH",
  "FTT",
  "XLM",
  "MANA",
  "HBAR",
  "VET",
  "LEO",
  "ETC",
  "KLAY",
  "AXS",
  "EGLD",
  "FIL",
  "SAND",
  "XMR",
  "THETA",
  "HNT",
  "XTZ",
  "MIOTA",
  "ONE",
  "EOS",
  "AAVE",
  "CAKE",
  "GRT",
  "BTT",
  "BABYDOGE",
  "BSV",
  "STX",
];

export const user = {
  uid: "8HDeNuCpyusuG7ObjzbdPzRyVhYC",
  email: "avi111@gmail.com",
  emailVerified: true,
  isAnonymous: false,
  providerData: [
    {
      providerId: "google.com",
      uid: "0891826412618452482876655656109387851822",
      displayName: null,
      email: "avi111@gmail.com",
      phoneNumber: null,
      photoURL: null,
    },
  ],
  stsTokenManager: {
    refreshToken:
      "Dlw1AXk80PEufziYLIMCNFofUI49C_qz1KAsLCKBcJn4dqmlT9wrPAJ006cBajzgno9m4s45qDxI0Gh-NxwLyk_dPMx1qiKnBx2U3uN8buC3BM1rKpsttURVEqBnUTcIuX5MtqKZLz3dSKM7rulYeKVTl9Gy8kmf5nCLueAwKhIPVXqJpUV5jCIVoehYz7ETErJs-AVob0II",
    accessToken:
      "eyJhbGciOiJub25lIiwidHlwIjoiSldUIn0.eyJlbWFpbCI6ImF2aTExMUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXV0aF90aW1lIjoxNjQ2MDc0NTkxLCJ1c2VyX2lkIjoiOEhEZU51Q3B5dXN1RzdPYmp6YmRQelJ5VmhZQyIsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiYXZpMTExQGdtYWlsLmNvbSJdLCJnb29nbGUuY29tIjpbIjA4OTE4MjY0MTI2MTg0NTI0ODI4NzY2NTU2NTYxMDkzODc4NTE4MjIiXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn0sImlhdCI6MTY0NjA3NDU5MSwiZXhwIjoxNjQ2MDc4MTkxLCJhdWQiOiJjb2lucGFybGlhbWVudC01MWFlMSIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9jb2lucGFybGlhbWVudC01MWFlMSIsInN1YiI6IjhIRGVOdUNweXVzdUc3T2JqemJkUHpSeVZoWUMifQ.",
    expirationTime: 1646078191542,
  },
  createdAt: "1640039394107",
  lastLoginAt: "1646074591537",
  apiKey: "AIzaSyDZvpoggvWLqqr-QUhOxOyq4n5aaoiWMXk",
  appName: "[DEFAULT]",
};

export const userInfo = {
  favorites: ["NEAR"],
  country: "",
  lastName: "",
  leader: ["8HDeNuCpyusuG7ObjzbdPzRyVhYC"],
  address: "",
  displayName: "mushon",
  subscribers: ["8HDeNuCpyusuG7ObjzbdPzRyVhYC"],
  mfa: false,
  admin: true,
  token:
    "cWHlYVrY35Q8J23H7p59fW:APA91bFYQlJyctdJHjVfeY_9FpDDzw4-8Knnl64lCumsBDcX6FoFCFpkam4QJwlV24Nz-syGE7_YmhHoqOVySCljYmHZbcFUMwmlAR5wl0qs_eTWRbRmTCFK1VpVro4JNXaTNVwcVhPT",
  firstName: "",
  children: [],
  lang: "english",
  email: "avi111@gmail.com",
  status: {
    color: Colors.SILVER,
    givenCPM: 1,
    index: 0,
    weight: 1,
    name: "Member",
    share: 50,
  },
  voteStatistics: {
    score: 53,
    total: 62,
    rank: 0,
    commission: 0,
    successful: 50,
  },
};
