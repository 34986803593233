import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import CardShow from "./Components/Pairs/CardShow";
import CircularProgress from "./Components/circleProgressbar";

const App = () => {
  const [socketConnect, setSocketConnect] = useState<any>(false);
  const storedCoinsPrice = localStorage.getItem("CoinsPrice");
  const getCoinPrice = storedCoinsPrice ? JSON.parse(storedCoinsPrice) : {};
  const [localPrice, setLocalPrice] = useState<any>(getCoinPrice);
  const [coins, setCoins] = useState();

  var currentUrl = window.location.search;
  var urlParams = new URLSearchParams(currentUrl);
  var cardName = urlParams.get("coin") || "btc";
  const location = useLocation();  
  const pathname = location.pathname;
  useEffect(() => {
    connect();
  }, []);

  useEffect(() => {
    const body = document.querySelector("body") as HTMLBodyElement;
    // const invalidClasses = ["legendary", "rare", "epic", "common", "uncommon"];
    const classes = pathname
      .slice(1)
      .split("/")
      // .filter((c) => c && !invalidClasses.includes(c.toLowerCase()));
    body.classList.remove(...Array.from(body.classList));
    
    classes.forEach((c) => body.classList.add(`${c.toLowerCase()}widget`));
  }, [pathname]);

  const connect = () => {
    const socket = new WebSocket(
      `wss://stream.binance.com:9443/ws/${cardName}usdt@trade`
    );
    socket.onopen = () => {
      setSocketConnect(true);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // console.log("Received message:", data);
      setCoins(data?.p);
    };

    socket.onclose = (event: any) => {
      setSocketConnect(false);
      // console.log('WebSocket connection closed', event);
      if (event.code !== 1000) {
        // console.log('WebSocket Attempting to reconnect in 5 seconds...');
        setTimeout(() => {
          connect();
        });
      }
    };
  };

  return (
    <div>
      <Routes>
        <Route path="/" element={<CardShow coins={coins} />} />
        <Route path="/cmp" element={<CircularProgress />} />
      </Routes>
    </div>
  );
};

export default App;
