import React, { SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import styled from "styled-components";
import { Buttons } from '../Components/Atoms/Button/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { listData } from '../Components/Pairs/utils';
import { VoteButton } from '../common/utils/SoundClick';
import "../Components/cp_widget.css";
import { coins as coinsName } from '../Components/Pairs/testData';

const CoinContainer = styled.div`
  border-top-color: ${(props: { winner: boolean }) =>
    props.winner ? "#6352E8" : "transparent"};
  border-top-style: solid;
  border-top-width: 4px;
`;

const CoinVoteTimer = styled.span`
  // font-size: 14px;
  color: #6352e8;
  `;
// line-height: 14px;

const LineImg = styled.div`
  // height: 60px;
  // width: 19px;
`;
interface Rdiv {
  // submit: boolean,
  backcolor: string
}

const PairsVoteVs = styled.span`
  font-size: ${window.screen.width > 676 ? "14px" : "10px"};
  color: #6352e8;
`;
interface ChildComponentProps {
  showPopUp?: any;
  setShowPopUp?: any;
  voteDirection?: number;
  coins?: any;
  voteLastPrice?: any
  startprice?: any
  activeTime?: any;
  impactValue?: any
}

const ModalForResult: React.FC<ChildComponentProps> = ({ showPopUp, setShowPopUp, coins, voteLastPrice, voteDirection, startprice, activeTime, impactValue }) => {
  const [lastPrice, setLastPrice] = useState<any>(coins)
  const twoDigitLastPrice = lastPrice && parseFloat(lastPrice)?.toFixed(2)
  const twoDigitStartPrice = startprice && parseFloat(startprice)?.toFixed(2)

  useEffect(() => {
    if (showPopUp) {
      handleShow();
    }
    VoteButton(true)
  }, [showPopUp])

  const [voteImpact, setVoteImpact] = useState('')

  var currentUrl = window.location.search;
  var urlParams = new URLSearchParams(currentUrl);
  var cardName = urlParams.get("coin") || "BTC"
  var version: any =  urlParams.get("v") || 2

  const [show, setShow] = useState(false);
  // const setVoteDetails = useContext(VoteDispatchContext);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    // console.log("i am clickable")
    setShowPopUp(false)
    setShow(false);
  };
  let params = useParams();
  const { id } = params;
  // console.log(params,"params")
  // console.log(window.location,"loaction")
  // @ts-ignore
  const cardData = { ...listData[0] }
  var currentUrl = window.location.search;
  // Use the URLSearchParams API to parse the URL and get the value of the "username" parameter
  var urlParams = new URLSearchParams(currentUrl);

  if (urlParams.has('username')) {
    var username = urlParams.get('username');
    // console.log(username,"usernam 11");
  } 

  return (
    <div className='' onClick={e => {
      e.stopPropagation();
    }}>
      <Modal show={show} onHide={handleClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 1060 ,overflow: 'hidden',backgroundColor:"gray" }}
        animation={false}
        className='coinCardshopModal resultsModal goodJobModal'
      >
        {/* <div className='d-flex justify-content-between'>
          <div className='text-center mb-2' style={{
            color: "#6352e8",
            fontWeight: "300",
            marginLeft: `${window.screen.width < 767 ? "10%" : ""}`
          }}>
          </div>
          <button className="btn-close " aria-label="Close"
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
              handleClose()
            }}
          ></button>
        </div> */}
        <Modal.Body>
          <div className=' w-100 good-job-body'
          style={{display:'inline-grid',justifyContent: 'center'}}
          >
            <div
              // className={`${window.screen.width < 767 ? "" : ""}`}
              className={`${window.screen.width < 767 ? "" : ""}  d-flex justify-content-between`}
            >
            </div>
            <div style={{ minHeight: "100%" }} className=" text-center">
              {/* {console.log("difference :: ",startprice,voteLastPrice,voteDirection)} */}
              {version == 1 ?
               <div className=''
                style={{ fontSize: "12px" }}
              >
                <img src={process.env.PUBLIC_URL + `/images/logos/${cardName?.toUpperCase()}.svg`} alt="" width={"80px"} style={{ height: "50px" }} />
                <p style={{ marginBottom: "0rem", fontWeight: "Bold", fontSize: "20px" }}>{coinsName[cardName?.toUpperCase()]?.name}</p>
                <p style={{ marginBottom: "0rem", fontSize: "17px" }}>{cardName?.toUpperCase()}</p>
                {/* <p className='appr-title'>Good job!</p> */}
                <p style={{marginBottom:"0rem",fontWeight:"Bold",fontSize:"20px"}}>{Math.floor(activeTime / 1000) + "Sec"}</p>
                <p style={{fontSize:"20px"}}>{voteDirection === 1 ? "Bull " : "Bear "}{'$'+twoDigitStartPrice}</p>
                <p  style={{marginBottom:"0rem",fontSize:"20px",color:"#6352e8"}}>{"Vote Result"}</p>
                <p style={{ fontSize: "20px", color: `${twoDigitLastPrice > twoDigitStartPrice ? "green" : twoDigitLastPrice ==  twoDigitStartPrice ?"black":"red"}`}}>{voteDirection === 1 && twoDigitLastPrice >= twoDigitStartPrice ? "Bull " : "Bear "}{'$'+twoDigitLastPrice}</p>
                <p style={{fontSize:"17px"}}>{"Vote impact :"} {impactValue.toUpperCase()}</p>
              </div>
              :
              <div className='GoodModal'
                style={{ fontSize: "12px",display:'inline-grid',justifyContent: 'center',padding: '30px 0px 70px' }}
              >
                <p className='appr-title'>Good job 👍🏻 </p>
                {/* <img src={process.env.PUBLIC_URL + `/images/logos/${cardName?.toUpperCase()}.svg`} alt="" width={"80px"} style={{ height: "50px" }} /> */}
                {/* <p style={{ marginBottom: "0rem", fontWeight: "Bold", fontSize: "20px" }}>{coinsName[cardName?.toUpperCase()]?.name}</p> */}
                {/* <p style={{ marginBottom: "0rem", fontSize: "17px" }}>{cardName?.toUpperCase()}</p> */}
                {/* <p style={{marginBottom:"0rem",fontWeight:"Bold",fontSize:"20px"}}>{Math.floor(activeTime / 1000) + "Sec"}</p> */}
                {/* <p style={{fontSize:"20px"}}>{voteDirection === 1 ? "Bull " : "Bear "}{'$'+twoDigitStartPrice}</p> */}
                {/* <p  style={{marginBottom:"0rem",fontSize:"20px",color:"#6352e8"}}>{"Vote Result"}</p> */}
                {/* <p style={{ fontSize: "20px", color: `${twoDigitLastPrice > twoDigitStartPrice ? "green" : twoDigitLastPrice ==  twoDigitStartPrice ?"black":"red"}`}}>{voteDirection === 1 && twoDigitLastPrice >= twoDigitStartPrice ? "Bull " : "Bear "}{'$'+twoDigitLastPrice}</p> */}
                {/* <p style={{fontSize:"17px"}}>{"Vote impact :"} {impactValue.toUpperCase()}</p> */}
              </div>
}
            </div>
          </div>
          <div className='d-flex justify-content-center mt-2 mb-2 mb-md-0 resultModalBtn'>
            <Buttons.Primary
              style={{ textTransform: "unset" }}
              onClick={(e) => {
                e.stopPropagation();
                if (username) {
                  window.open(`https://coinparliament.com/?refer=${username}`, "_blank");
                } else {
                  window.open(`https://coinparliament.com/`, "_blank");
                }
              }}
            >
              Start earning
            </Buttons.Primary>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default React.memo(ModalForResult);